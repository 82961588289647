import customQueryStringify from "../../helpers/queryString";
import http from "../../services/api";
import store from "../index";
import * as types from "./../types/parceiros";

export function loadParceiros(query) {
  const qrt = customQueryStringify(query);

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_LOADING, payload: true });
    http.get(`/api/v1/adm/parceiros/listar?${qrt}`).then(
      (response) => {
        store.dispatch([
          { type: types.PARCEIROS_DATA, payload: response.data.parceiros },
          { type: types.PARCEIROS_DATA_PAGE, payload: response.data.page },
          { type: types.PARCEIROS_DATA_PER_PAGE, payload: response.data.per_page },
          { type: types.PARCEIROS_DATA_TOTAL, payload: response.data.total },
          { type: types.PARCEIROS_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function loadParceiroEspecifico(doc) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_LOADING, payload: true });
    http.get(`/api/v1/adm/parceiros/listar/${doc}`).then(
      (response) => {
        store.dispatch([
          { type: types.PARCEIRO_DATA, payload: response.data.parceiros },
          { type: types.CATEGORIAS_DATA, payload: response.data.categorias },
          { type: types.PARCEIROS_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function LoadHorariosDeAtendimentoPorCategoria(id) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_LOADING, payload: true });
    http.get(`/api/v1/adm/parceiros/categorias/horarios/${id}`).then(
      (response) => {
        store.dispatch([
          { type: types.HORARIOS_CATEGORIAS_DATA, payload: response.data },
          { type: types.PARCEIROS_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function CadastrarHorariosDeAtendimentoPorCategoria(id, data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_LOADING, payload: true });
    http.post(`/api/v1/adm/parceiros/cadastro-parceiro-horarios/${id}`, data).then(
      (response) => {
        store.dispatch([{ type: types.PARCEIROS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionAceceptedOuRejected(cpf, data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_LOADING, payload: true });
    http.put(`/api/v1/adm/parceiros/validar/${cpf}`, data).then(
      (response) => {
        store.dispatch([{ type: types.PARCEIROS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionCadatrarParceiroWl(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_LOADING, payload: true });
    http.post(`/api/v1/adm/parceiros/cadastro-parceiro`, data).then(
      (response) => {
        store.dispatch([{ type: types.PARCEIROS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionCadastrarParceiroCategoriaWL(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_LOADING, payload: true });
    http.post(`/api/v1/adm/parceiros/cadastro-parceiro-categoria`, data).then(
      (response) => {
        store.dispatch([{ type: types.PARCEIROS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionDeletarCategoriaParceiro(data) {
  const qrt = customQueryStringify(data);
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_LOADING, payload: true });
    http.delete(`/api/v1/adm/parceiros/categorias/remover?${qrt}`).then(
      (response) => {
        store.dispatch([{ type: types.PARCEIROS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionEditarCategoria(data) {
  const qrt = customQueryStringify(data);
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_LOADING, payload: true });
    http.put(`/api/v1/adm/parceiros/categorias/editar`, data).then(
      (response) => {
        store.dispatch([{ type: types.PARCEIROS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionEditarParceiro(data) {
  const qrt = customQueryStringify(data);
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_LOADING, payload: true });
    http.post(`/api/v1/adm/parceiros/editar-parceiro`, data).then(
      (response) => {
        store.dispatch([{ type: types.PARCEIROS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}



export function CadastrarUsuarioParceiroAction(data) {
  console.log('data', data)
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_LOADING, payload: true });
    http.post(`/api/v1/adm/parceiros/cadastrar/usuario`, data).then(
      (response) => {
        store.dispatch([{ type: types.PARCEIROS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_LOADING, payload: false });
        reject(e.response.data);
      }
    );
  });
}

export function loadUsuarioParceiros(query) {
  const qrt = customQueryStringify(query);

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_USUARIOS_LOADING, payload: true });
    http.get(`/api/v1/adm/parceiros/load/usuario?${qrt}`).then(
      (response) => {
        store.dispatch([
          { type: types.PARCEIROS_USUARIOS_DATA, payload: response.data.usuarios },
          { type: types.PARCEIROS_USUARIOS_DATA_PAGE, payload: response.data.page },
          { type: types.PARCEIROS_USUARIOS_DATA_PER_PAGE, payload: response.data.per_page },
          { type: types.PARCEIROS_USUARIOS_DATA_TOTAL, payload: response.data.total },
          { type: types.PARCEIROS_USUARIOS_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_USUARIOS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}



export function DeletarUsuarioParceiro(data) {
  console.log('data', data)
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_LOADING, payload: true });
    http.delete(`/api/v1/adm/parceiros/usuario/excluir/${data}`).then(
      (response) => {
        store.dispatch([{ type: types.PARCEIROS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_LOADING, payload: false });
        reject(e.response.data);
      }
    );
  });
}