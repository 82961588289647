import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CadastrarUsuarioParceiroAction } from "store/actions/parceiros";
import { useState } from "react";
import Loading from "components/Loading";
import ModalComponent from "components/Modal";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CadastrarUsuarioParceiros({
  open,
  setOpen,
  data,
  setClose,
  updateList,
}) {
  const handleClose = () => setClose(false);
  const [loading, setLoading] = useState(false);
  const [cadastro, setCadastro] = useState({
    nome: "",
    email: "",
    password: "",
  });
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [messageError, setMessageErrror] = useState("");

  async function CadastroFnc() {
    const dataLoad = {
      nome: cadastro.nome,
      email: cadastro.email,
      password: cadastro.password,
      wl_parceiro_id: data.id,
    };

    setLoading(true);
    try {
      await CadastrarUsuarioParceiroAction(dataLoad);
      setLoading(false);
      updateList();
      handleClose();
      setCadastro({
        nome: "",
        email: "",
        password: "",
      });
    } catch (e) {
      updateList();
      setMessageErrror(e.message);
      setShowErrorAlert(true);
      setCadastro({
        nome: "",
        email: "",
        password: "",
      });
      setTimeout(() => {
        setShowErrorAlert(false);
        setMessageErrror("");
      }, 3000);
      setLoading(false);
    }
  }

  // nome, email, password, wl_parceiro_id - para cadastro

  return (
    <ModalComponent open={open} width="30%">
      <Loading loading={loading} />
      Cadastrar usuário para validar os cupons de desconto
      <MDBox pt={4} pb={3} px={3}>
        <MDBox>
          <MDBox mb={2}>
            <MDInput
              type="nome"
              label="Nome"
              fullWidth
              value={cadastro.nome}
              onChange={(e) =>
                setCadastro({ ...cadastro, nome: e.target.value })
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              fullWidth
              value={cadastro.email}
              onChange={(e) =>
                setCadastro({ ...cadastro, email: e.target.value })
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="Senha"
              fullWidth
              value={cadastro.password}
              onChange={(e) =>
                setCadastro({ ...cadastro, password: e.target.value })
              }
            />
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={() => CadastroFnc()}
            >
              Cadastrar usuário
            </MDButton>
          </MDBox>
          {messageError}
          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={handleClose}
            >
              Fechar cadastro
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </ModalComponent>
  );
}

export default CadastrarUsuarioParceiros;
