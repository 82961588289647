import * as React from "react";
import { useState } from "react";
import CadastrarUsuarioParceiros from "./cadastro";
import ModalComponent from "components/Modal";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
} from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { loadUsuarioParceiros } from "store/actions/parceiros";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as types from "../../../store/types/parceiros";
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  LanguageOutlined,
} from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import { DeletarUsuarioParceiro } from "store/actions/parceiros";
import Loading from "components/Loading";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ListagemUsuarioParceiros({ open, setOpen, data }) {
  const [openModalCadastroUsuario, setOpenModalCadastroUsuario] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    parceirosUsuarios,
    parceirosUsuarioloading,
    parceirosUsuarioPage,
    parceirosUsuarioPerPage,
    parceirosUsuarioTotal,
  } = useSelector((state) => state.parceiros);

  const [filtro, setFiltro] = useState("");
  const [filtroAtualizado, setFiltroAtualizado] = useState("");
  const [resetPage, setResetPage] = useState(false);

  async function GetDataLoad() {
    const loadData = {
      page: resetPage ? 1 : parceirosUsuarioPage,
      per_page: parceirosUsuarioPerPage,
      filtro: filtroAtualizado,
      wl_parceiro_id: data.id,
    };

    await loadUsuarioParceiros(loadData);
    setFiltroAtualizado("");
    setResetPage(false);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filtro === "") {
        GetDataLoad(); // Se filtro está vazio, chama a função GetDataLoad diretamente
      } else {
        setFiltroAtualizado(filtro);
        setResetPage(true);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [filtro, parceirosUsuarioPage, data]);

  useEffect(() => {
    if (filtroAtualizado !== "") {
      GetDataLoad();
    }
  }, [filtroAtualizado, parceirosUsuarioPage, resetPage]);

  const navigatePages = (validations) => {
    dispatch({
      type: types.PARCEIROS_USUARIOS_DATA_PAGE,
      payload: validations,
    });
  };

  const columns = [
    { Header: "Cliente", accessor: "nome", align: "left" },
    { Header: "Email", accessor: "email", align: "left" },
    { Header: "Ação", accessor: "action", align: "center" },
  ];

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});

  const handleClickOpen = (data) => {
    setOpenDialog(true);
    setDialogData(data);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData({});
  };

  async function ExcluirUsuarioParceiro() {
    setLoading(true);

    try {
      await DeletarUsuarioParceiro(dialogData.up_id);
      GetDataLoad();
      setLoading(false);
      handleCloseDialog();
    } catch (e) {
      setLoading(false);
    }
  }

  function DialogDelUser() {
    return (
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Deseja realmente excluir o usuário ${dialogData.nome}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você está prestes a excluir este parceiro do sistema. Ao fazer isso,
            o usuário associado perderá imediatamente o acesso ao sistema. Caso
            deseje acessar novamente, será necessário realizar um novo cadastro.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={ExcluirUsuarioParceiro} autoFocus>
            Sim, excluir parceiro!
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const rows = parceirosUsuarios.map((item) => {
    return {
      nome: <>{item.nome}</>,
      email: <>{item.email}</>,
      status:
        item.status === "A" ? (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent="Ativo"
              color="success"
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ) : (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent="Inativo"
              color="dark"
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
      action: (
        <Button onClick={() => handleClickOpen(item)} autoFocus>
          Excluir
        </Button>
      ),
    };
  });

  return (
    <ModalComponent open={open} width="50%">
      <Loading loading={loading} />

      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button onClick={() => setOpenModalCadastroUsuario(true)}>
          cadastrar usuário
        </Button>
        <Button onClick={() => setOpen(false)}>Fechar | X</Button>
      </div>

      <Paper elevation={1} style={{ padding: 5 }}>
        <div style={{fontFamily: 'Poppins', }}>


        Você pode cadastrar usuários para que o parceiro {data.nome} tenha
        acesso à plataforma de validação de cupons.{" "}
        <a href="https://parceiro.4u.app.br/login" target="_blank" ><LanguageOutlined fontSize="12px" style={{fontSize: "12px"}} /> Site validação de cupom</a>
        </div>
        <Divider />
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
        <div style={{ margin: 15 }}>
          <div
            style={{
              marginRight: 30,
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {+parceirosUsuarioPage !== 1 && (
                <button
                  onClick={() => navigatePages(+parceirosUsuarioPage - 1)}
                >
                  <KeyboardArrowLeftOutlined /> Anterior{" "}
                </button>
              )}

              {parceirosUsuarios?.length !== 0 && (
                <button
                  style={{ marginLeft: 20 }}
                  onClick={() => navigatePages(+parceirosUsuarioPage + 1)}
                >
                  Próximo <KeyboardArrowRightOutlined />
                </button>
              )}
            </div>
            <p
              style={{
                fontSize: 14,
                backgroundColor: "transparent",
                fontFamily: "Poppins",
              }}
            >
              Pagina {parceirosUsuarioPage} | Total de usuarios{" "}
              {parceirosUsuarioTotal}
            </p>
          </div>
        </div>
      </Paper>

      <CadastrarUsuarioParceiros
        open={openModalCadastroUsuario}
        setOpen={setOpenModalCadastroUsuario}
        setClose={setOpenModalCadastroUsuario}
        data={data}
        updateList={GetDataLoad}
      />

      <DialogDelUser />
    </ModalComponent>
  );
}

export default ListagemUsuarioParceiros;
