export const PARCEIROS_LOADING = 'PARCEIROS_LOADING';
export const PARCEIROS_DATA = 'PARCEIROS_DATA';
export const PARCEIRO_DATA = 'PARCEIRO_DATA';
export const CATEGORIAS_DATA = 'CATEGORIAS_DATA';
export const HORARIOS_CATEGORIAS_DATA = 'HORARIOS_CATEGORIAS_DATA';


export const PARCEIROS_DATA_PAGE = "PARCEIROS_DATA_PAGE";
export const PARCEIROS_DATA_PER_PAGE = "PARCEIROS_DATA_PER_PAGE";
export const PARCEIROS_DATA_TOTAL = "PARCEIROS_DATA_TOTAL";




export const PARCEIROS_USUARIOS_DATA = "PARCEIROS_USUARIOS_DATA"
export const PARCEIROS_USUARIOS_DATA_PAGE = "PARCEIROS_USUARIOS_DATA_PAGE"
export const PARCEIROS_USUARIOS_DATA_PER_PAGE = "PARCEIROS_USUARIOS_DATA_PER_PAGE"
export const PARCEIROS_USUARIOS_DATA_TOTAL = "PARCEIROS_USUARIOS_DATA_TOTAL"
export const PARCEIROS_USUARIOS_LOADING = "PARCEIROS_USUARIOS_LOADING"