import * as types from "../types/parceiros";

const INITIAL_STATE = {
  loading: false,
  parceiros: [],
  parceiro: {},
  categorias: [],
  horariosCategorias: [],
  page: 1,
  per_page: 10,
  total: 0,
  parceirosUsuarios: [],
  parceirosUsuarioPage: 1,
  parceirosUsuarioPerPage: 10,
  parceirosUsuarioTotal: 0,
  parceirosUsuariosLoading: false

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PARCEIROS_LOADING:
      return { ...state, loading: action.payload };

    case types.PARCEIROS_DATA:
       return { ...state, parceiros: action.payload };

    case types.PARCEIRO_DATA:
       return { ...state,   parceiro: action.payload };

    case types.CATEGORIAS_DATA:
       return { ...state,   categorias: action.payload };

    case types.HORARIOS_CATEGORIAS_DATA:
       return { ...state,   horariosCategorias: action.payload };

       case types.PARCEIROS_DATA_PAGE:
        return { ...state, page: action.payload };
  
      case types.PARCEIROS_DATA_PER_PAGE:
        return { ...state, per_page: action.payload };
  
      case types.PARCEIROS_DATA_TOTAL:
        return { ...state, total: action.payload };


  
      case types.PARCEIROS_USUARIOS_DATA:
        return { ...state, parceirosUsuarios: action.payload };
  
      case types.PARCEIROS_USUARIOS_DATA_PAGE:
        return { ...state, parceirosUsuarioPage: action.payload };
  
      case types.PARCEIROS_USUARIOS_DATA_PER_PAGE:
        return { ...state, parceirosUsuarioPerPage: action.payload };
  
      case types.PARCEIROS_USUARIOS_DATA_TOTAL:
        return { ...state, parceirosUsuarioTotal: action.payload };
  
      case types.PARCEIROS_USUARIOS_LOADING:
        return { ...state, parceirosUsuariosLoading: action.payload };



    default:
      return state;
  }
};

