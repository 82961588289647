

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


import typography from "assets/theme/base/typography";
import { versionApp } from "services/baseURL";

function Footer({ company, links }) {

  const { size } = typography;



  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}, feito com
        <MDBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}>
          <Icon color="inherit" fontSize="inherit">
            favorite
          </Icon>
        </MDBox>
        por
        <Link  target="_blank">
          <MDTypography variant="button" fontWeight="medium">
            &nbsp;Robert Andrade&nbsp;
          </MDTypography>
        </Link>
        para uma web melhor. V{versionApp}
      </MDBox>

    </MDBox>
  );
}



export default Footer;
